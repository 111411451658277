import React from "react";
import { NavLink, Link, Redirect } from "react-router-dom";

const Footer = (props) => (
	<footer className="page-footer font-small text-white lighten-5 bg_verde mt-3">
		<div className="bg_verde_oscuro">
			<div className="container">
				<div className="row py-4 d-flex align-items-center">
					<div className="col-6 col-md-3 text-center text-md-left mb-4 mb-md-0">
						<h6 className="mb-0">Descargate Nuestra App</h6>
					</div>
					<div className="col-6 col-md-3 text-center text-md-right">
						<a className="tw-ic link-blanco" href="https://play.google.com/store/apps/details?id=com.globaltecnosapp" target="_blank">
							<i className="fab fa-google-play white-text mr-4"> </i>
						</a>
						<a className="li-ic link-blanco" href="https://apps.apple.com/ar/app/globaltecnos/id1465825103" target="_blank">
							<i className="fab fa-apple white-text mr-4"> </i>
						</a>
					</div>

					<div className="col-6 col-md-3 text-center text-md-left mb-4 mb-md-0">
						<h6 className="mb-0">Nuestras Redes</h6>
					</div>

					<div className="col-6 col-md-3 text-center text-md-right">
						<a className="tw-ic link-blanco" href="https://twitter.com/globaltecnos" target="_blank">
							<i className="fab fa-twitter white-text mr-4"> </i>
						</a>
						<a className="li-ic link-blanco" href="https://www.linkedin.com/company/globaltecnos-s-a-/" target="_blank">
							<i className="fab fa-linkedin-in white-text mr-4"> </i>
						</a>
						<a className="ins-ic link-blanco" href="https://www.youtube.com/user/Globaltecnos" target="_blank">
							<i className="fab fa-youtube white-text"> </i>
						</a>
					</div>
				</div>
			</div>
		</div>

		<div className="container text-center text-md-left mt-5">
			<div className="row mt-3 dark-grey-text">
				<div className="col-12 col-md-3 col-lg-4 col-xl-3 mb-4">
					<div className="row">
						<div className="col-6 col-md-12">
							<img src="https://globaltecnos.com.ar/imgs/iso2.png" width="120px" />
							<h6 className="text-uppercase font-weight-bold">Globaltecnos</h6>
							<hr className="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
						</div>
						<div className="col-6 col-md-12 text-center">
							<div className="clearfix pt-2" style={{ width: "80px" }}>
								<a href="http://qr.afip.gob.ar/?qr=YWMATWgEkEmzs2-B2YELHQ,," target="_F960AFIPInfo" className="afipqr">
									<img src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0" width="100%" />
								</a>
							</div>
						</div>
					</div>
				</div>

				<div className="col-6 col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
					<h6 className="text-uppercase font-weight-bold">QUIENES SOMOS</h6>
					<hr className="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
					<p>
						<NavLink className="text-white" to="/nosotros/equipo">
							Equipo
						</NavLink>
					</p>
					<p className="mb-4">
						<NavLink className="text-white" to="/nosotros/amigos">
							Nos Acompañan 
						</NavLink>
					</p>

					<h6 className="text-uppercase font-weight-bold ">SECCIONES</h6>
					<hr className="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
					<p>
						<NavLink className="text-white" to="/mercados">
							Mercados
						</NavLink>
					</p>
					<p>
						<NavLink className="text-white" to="/contacto">
							Contacto
						</NavLink>
					</p>
				</div>

				<div className="col-6 col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
					<h6 className="text-uppercase font-weight-bold">SERVICIOS</h6>
					<hr className="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
					<p>
						<NavLink className="text-white" to="/servicios/mercado">
							Análisis de mercados
						</NavLink>
					</p>
					<p>
						<NavLink className="text-white" to="/servicios/ganaderia">
							Ganadería
						</NavLink>
					</p>
					<p>
						<NavLink className="text-white" to="/servicios/agricultura">
							Agricultura
						</NavLink>
					</p>
					<p>
						<NavLink className="text-white" to="/servicios/viajes">
							Viajes
						</NavLink>
					</p>
					<p>
						<NavLink className="text-white" to="/servicios/administracion">
							Administración
						</NavLink>
					</p>
					<p>
						<NavLink className="text-white" to="/servicios/capital_humano">
							Capital Humano
						</NavLink>
					</p>
				</div>

				<div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
					<h6 className="text-uppercase font-weight-bold">Datos de Contacto</h6>
					<hr className="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
					<p style={{ marginBottom: "0px" }}>

						<i className="fas fa-home mr-3"></i> Uruguay 911 4to A
					</p>
					<p style={{ fontSize: "13px", marginLeft: "40px" }}>CP C1015 | BUENOS AIRES | ARG</p>
					<p>
						<i className="fas fa-envelope mr-3"></i> info@globaltecnos.com.ar
					</p>
					{1 == 3 && (
						<p>
							<i className="fas fa-phone mr-3"></i> +54 11 47 74 04 13
						</p>
					)}
				</div>
			</div>
		</div>
		{1 == 3 && (
			<div className="footer-copyright text-center text-white py-3  bg_gris">
				© 2020 Copyright:
				<a className=" text-white" href="https://globaltecnos.com.ar/">
					{" "}
					globaltecnos.com.ar
				</a>
			</div>
		)}
	</footer>
);

export default Footer;
