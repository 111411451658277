import axios from 'axios'
import  fs from 'fs'
import moment from 'moment'
import { usuarioRef, sesionesRef, authRef } from "../config/firebase";

export const GET_INI = "GET_INI";
export const SEND_USER_MSJ = "SEND_USER_MSJ";
export const GET_VIDEOS = "GET_VIDEOS";
export const GET_TWEETS = "GET_TWEETS";
export const GET_ACTUALIDAD = "GET_ACTUALIDAD";
export const GET_MERCADOS = "GET_MERCADOS";
export const GET_NOTAS = "GET_NOTAS";
export const SEND_CONTACTO = "SEND_CONTACTO";

const url_data = "https://globaltecnos.com.ar:7810/institucional/ini"
const url_videos = "https://www.googleapis.com/youtube/v3/search?channelId=UCUZ8xrx4LcoQHCk3_YbOFkw&order=date&part=snippet&type=video&maxResults=4&key=AIzaSyDAbjogNksmxW-ps7l4mN3f4XVhxsAVjWg";
const url_mercados = "https://globaltecnos.com.ar:7813/api/data/info_comercial/1";
const url_actualidad = "https://globaltecnos.com.ar:7813/api/data/home";
const url_notas = "https://globaltecnos.com.ar:7813/api/data/actualidad";
const url_contacto = "https://globaltecnos.com.ar:7813/api/data/contacto";

export const getIni = async () => {
	try {
		const requestActualidad = await axios.get(url_actualidad);
		console.log(requestActualidad);
		const request = await axios.get(url_data);
		console.log(request)
		return {
			type: GET_INI,
			payload: request,
			actualidad: requestActualidad.data
		};
	} catch (error) {
		return {
			type: SEND_USER_MSJ,
			msj: error,
			msj_tipo: "alert-danger"
		};
	}
}

export const getVideos = async () => {
	try {
		const request = await axios.get(url_videos);
		console.log(request)
		return {
			type: GET_VIDEOS,
			payload: request.data,
		};
	} catch (error) {
		console.log(error);
		return {
			type: SEND_USER_MSJ,
			data: error,
		};
	}
};

export const sendContacto = async (data) => {
	try {
		const request = await axios.post(url_contacto,data);
		return {
			type: SEND_CONTACTO,
			
		};
	} catch (error) {
		console.log(error);
		return {
			type: SEND_USER_MSJ,
			data: error,
		};
	}
};

export const getMercados = async () => {
	try {
		const request = await axios.get(url_mercados);
		console.log(request);
		return {
			type: GET_MERCADOS,
			payload: request,
		};
	} catch (error) {
		console.log(error);
		return {
			type: SEND_USER_MSJ,
			payload: error,
		};
	}
};

export const getActualidad = async () => {
	try {
		const request = await axios.get(url_actualidad);
		console.log(request)
		return {
			type: GET_ACTUALIDAD,
			payload: request,
		};
	} catch (error) {
		console.log(error);
		return {
			type: SEND_USER_MSJ,
			payload: error,
		};
	}
};

export const getNotas = async () => {
	try {
		const request = await axios.get(url_notas);
		return {
			type: GET_NOTAS,
			payload: request.data,
		};
	} catch (error) {
		console.log(error);
		return {
			type: SEND_USER_MSJ,
			payload: error,
		};
	}
};

export const getTweets = async () => {
}