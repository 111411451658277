import React, { Component } from "react";
import { NavLink, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
//import { signOut  } from '../actions'
import moment from "moment";

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			suggestions: [{ id_cliente: "", nombre: "NO HAY" }],
			cliente_select: "",
			cliente_select_label: "",
			muestra_pedido: "block",
			muestra_combo: "block",
			menu: [
				//{ nombre: "Home", dest: "/" },
				/* { nombre: "CartaForm", dest: "/carta/1" },*/
			],
		};
	}

	componentDidMount() {}

	componentWillUnmount() {}

	componentDidUpdate(prevProps, prevState) {}

	render() {
		const Boton = (data) => {
			return (
				<li className="nav-item">
					<NavLink to={data.dest} className="nav-link">
						{data.nombre}
					</NavLink>
				</li>
			);
		};

		const Menu = (data) => {
			const menuItems = this.state.menu.map((item, i) => <Boton nombre={item.nombre} dest={item.dest} key={i} />);

			return <ul className="navbar-nav">{menuItems}</ul>;
		};

		return (
			<header style={{ height: "70px" }}>
				<nav className="navbar navbar-expand-lg navbar-light fixed-top" style={{ height: "70px", backgroundColor: "#fff" }}>
					<Link className="navbar-brand" to="/">
						<img src="https://globaltecnos.com.ar/imgs/isologo_naranja.png" height="45" className="d-inline-block align-top" alt="" />
					</Link>
					<button
						className="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div
						className="collapse navbar-collapse align-content-end"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
						id="navbarSupportedContent"
						style={{ justifyContent: "flex-end", textAlign: "end" }}
					>
						<ul className="navbar-nav">
							<li className="nav-item  naranja active">
								<NavLink className="nav-link  p-2 naranja monse" to="/mercados">
									{" "}
									MERCADOS{" "}
								</NavLink>
							</li>
							<li className="nav-item  naranja active">
								<NavLink className="nav-link  p-2 naranja monse" to="/servicios">
									{" "}
									SERVICIOS{" "}
								</NavLink>
							</li>
							<li className="nav-item  naranja active">
								<NavLink className="nav-link  p-2 naranja monse" to="/nosotros">
									{" "}
									QUIENES SOMOS{" "}
								</NavLink>
							</li>
							<li className="nav-item  naranja">
								<NavLink className="nav-link  p-2 naranja monse" to="/contacto">
									{" "}
									CONTACTO{" "}
								</NavLink>
							</li>
							{1 === 3 && (
								<li className="nav-item  naranja">
									<NavLink className="nav-link  p-2 naranja monse" to="/login">
										{" "}
										INGRESAR{" "}
									</NavLink>
								</li>
							)}
							<li className="nav-item p-2 naranja">
								<div className="container-fluid" style={{ justifyContent: "flex-end" }}>
									<div className="row">
										<div className="col">
											<a href="https://www.youtube.com/user/Globaltecnos" target="_blank" className="icon  naranja naranja">
												<i className="fab fa-youtube"></i>
											</a>
										</div>
										<div className="col">
											<a href="https://twitter.com/globaltecnos" target="_blank" className="icon p-2 naranja">
												<i className="fab fa-twitter"></i>
											</a>
										</div>
										<div className="col">
											<a href="https://www.linkedin.com/company/globaltecnos-s-a-/" target="_blank" className="icon p-2 naranja">
												<i className="fab fa-linkedin-in"></i>
											</a>
										</div>
									</div>
								</div>
							</li>
							<li className="nav-item p-2 naranja">
								<div className="container-fluid" style={{ justifyContent: "flex-end" }}>
									<div className="row">
										<div className="col">
											<a className="icon p-2 naranja" href="https://play.google.com/store/apps/details?id=com.globaltecnosapp" target="_blank">
												<i className="fab fa-google-play"> </i>
											</a>
										</div>
										<div className="col">
											<a className="icon p-2 naranja" href="https://apps.apple.com/ar/app/globaltecnos/id1465825103" target="_blank">
												<i className="fab fa-apple"> </i>
											</a>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</nav>
			</header>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		usuario: state.usuario,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		/*signOut: () => {
			dispatch(signOut());
		}*/
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
